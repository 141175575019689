import React from 'react';
import SectionContainer from '../../layout/sectionContainer';
import Villages from './villages';

const TopSection = ({
  villages,
  villageTitle,
  villageContent,
}) => {
  return (
    <SectionContainer>
      <Villages
        villages={villages}
        villageTitle={villageTitle}
        villageContent={villageContent}
      />
    </SectionContainer>
  );
};

export default TopSection;
