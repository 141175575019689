import React from 'react';
import {graphql} from 'gatsby';
import SEO from '../components/layout/seo';
import Layout from '../components/layout/layout';
import SectionWrapper from '../components/layout/sectionWrapper';
import TopSection from '../components/sections/eventsIndex/topSection';
import FilteringSection from '../components/sections/eventsIndex/filteringSection';
import FeaturedAboutFeatured from '../components/sections/eventsIndex/featuredAboutSection';
import LocalPartners from '../components/sections/eventsIndex/localPartnersSection';
import {sortEventsByDate} from '../utils/processDate';
import TitleSection from '../components/sections/eventsIndex/titleSection';

const EventsIndex = ({data}) => {
  const rawEvents = data.allSanityEvent.edges.map(({node}) => node);
  const events = sortEventsByDate(rawEvents);
  const department = data.sanityDepartment.name;
  const logos = data.logos.edges.map(({node}) => node);
  const {topTitle, partnersTitle} = data.sanityPage.pageContent[0];
  const {featuredTitle, featuredContent} = data.featured.pageContent[0];
  const {villageTitle, villageContent} = data.village.pageContent[0];
  // const information = (
  //   <div className="flex flex-col justify-center text-center">
  //     <p className="max-w-screen-sm mx-auto">En raison de difficultés techniques, le renseignement du site a pris du retard. Pour tout information concernant la Fête de la Science en région Pays de la Loire, nous vous invitons à consulter la page régionale du site national de la Fête de la Science :</p>
  //     <a className="inline-flex items-center justify-center px-4 py-2 mx-auto mt-4 space-x-1 text-sm font-bold leading-6 text-gray-700 uppercase whitespace-no-wrap transition duration-150 ease-in-out border border-transparent rounded-md w-min-content bg-edu hover:bg-eduLight focus:outline-none focus:shadow-outline-indigo active:bg-bg-eduLight"
  //       href="https://www.fetedelascience.fr/programme?search=&date=&accessibility_type=&type_of_animation=&age_groups=&thematic=&region=12&overseas=&country=&school_time_slot=all&online=all&nid=0&department=" target="_blank" rel="noreferrer">Programme (fetedelascience.fr)</a>
  //   </div>
  // )
  const information = undefined
  return (
    <Layout>
      <SEO title={department} />
      <SectionWrapper>
        <TitleSection title={topTitle} department={department} information={information} />
        <FilteringSection events={events} department={department} />
        <TopSection
          villages={data.villages.edges}
          villageTitle={villageTitle}
          villageContent={villageContent}
        />
        <FeaturedAboutFeatured
          featuredTitle={featuredTitle}
          featuredContent={featuredContent}
        />
        {logos.length > 0 && (
          <LocalPartners logos={logos} partnersTitle={partnersTitle} />
        )}
      </SectionWrapper>
    </Layout>
  );
};

export default EventsIndex;

export const query = graphql`
  query($departmentId: String!) {
    allSanityEvent(
      filter: {
        department: { id: { eq: $departmentId } }
        education: { eq: false }
      }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          village {
            id
            title
            timeSlots {
              endDate
              endTime
              startDate
              startTime
              isEducation
            }
          }
          department {
            name
          }
          featured
          eventCanceled
          _rawDescription
          description {
            children {
              text
            }
          }
          theme {
            id
            name
          }
          timeSlots {
            endDate
            endTime
            startDate
            startTime
            isEducation
          }
          format {
            id
            name
            formatIcon {
              asset {
                gatsbyImageData(width: 500)
              }
            }
          }
          image {
            asset {
              gatsbyImageData(width: 1200)
            }
            hotspot {
              x
              y
            }
          }
          audience {
            id
            name
          }
          audienceCustom {
            from
            to
          }
        }
      }
    }
    sanityDepartment(id: { eq: $departmentId }) {
      name
    }
    sanityPage(pageName: { eq: "Evénements par région" }) {
      pageContent {
        ... on SanityEventsIndexPageBlock {
          topTitle
          partnersTitle
        }
      }
    }
    featured: sanityPage(pageName: { eq: "Coups de Coeur" }) {
      pageContent {
        ... on SanityFeaturedBlock {
          featuredTitle
          featuredContent
        }
      }
    }
    village: sanityPage(pageName: { eq: "Village des Sciences" }) {
      pageContent {
        ... on SanityVillageBlock {
          villageContent
          villageTitle
        }
      }
    }
    logos: allSanityLogo(
      filter: { partners: { elemMatch: { id: { eq: $departmentId } } } }
    ) {
      edges {
        node {
          id
          name
          image {
            asset {
              gatsbyImageData(width: 500)
              url
            }
          }
        }
      }
    }
    villages: allSanityVillage(
      filter: { grandPublic: {eq: true}, department: { id: { eq: $departmentId } } } # sort: { fields: startDate___local, order: ASC }
    ) {
      edges {
        node {
          id
          title
          _rawDescription
          description {
            children {
              text
            }
          }
          department {
            name
          }
          slug {
            current
          }
          eventCanceled
          timeSlots {
            _key
            endDate
            endTime
            startDate
            startTime
          }
          image {
            asset {
              gatsbyImageData(width: 1200)
            }
            hotspot {
              x
              y
            }
          }
        }
      }
    }
  }
`;
